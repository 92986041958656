@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


/* .card {
  color: white;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
} */

.logo {
  font-family: 'Russo One', sans-serif;
  color: #ED3B0C;
}

.card {
  /* color: black; */
  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.31);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}



.fade-in-image {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}




#connectButton:before {
	background: url('../assets/handcashlogowhite.png') no-repeat scroll center center / 100% auto rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    color: #fff;
    height: 20px;
    margin-right: 12px;
    margin-bottom: 1px;
    position: relative;
    vertical-align: middle;
    width: 20px;
}


#connectButton:hover {
	background-image: linear-gradient(#31C475, #16B15D);
	top:1px;
	box-shadow: 0px 3px 6px hsla(0, 0%, 0%, .15);
}

#connectButton:active {
	background-image: linear-gradient(#38CB7C, #1CB462);
	position:relative;
	top:1px;
	box-shadow: 0px 0px 0px hsla(0, 0%, 0%, .15);
}


#connectButton{
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	font-family: 'Poppins', sans-serif;
	box-shadow: 0px 1px 3px hsla(0, 0%, 0%, .15);
	background-image: linear-gradient(#38CB7C, #1CB462);
	border-radius: 8px;
	display:inline-block;
	text-align: center;
	cursor:pointer;
	color:#ffffff;
	font-size:16px;
	font-weight:500;
	padding:8px 24px;
	text-decoration:none;
	transition: 0.3s;
	width: 100%;
	/* max-width: 320px; */
	vertical-align: middle;
	letter-spacing: 0.5px;
}

#connectButton.disabled{
  cursor: not-allowed;
  background-image: linear-gradient(#ade7c8, #6adb9f);
}


.price-tag{
  font-family: "Kode Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
