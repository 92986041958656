.send-button {
    cursor: pointer;
    align-items: center;
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.05), rgba(255, 255, 255, 0.05));
    border-radius: 50px;
    display: flex;
    height: 100px;
    justify-content: center;
    width: 100px;
    border-radius: 10px;
    font-size: 16px;
    box-shadow:
        3px 3px 4px 0 rgba(0, 0, 0, 0.1),
        -2px -2px 3px 0 rgba(255, 255, 255, 0.2);
}

.send-button:hover {
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
    box-shadow:
        3px 3px 4px 0 rgba(0, 0, 0, 0.15),
        -2px -2px 3px 0 rgba(255, 255, 255, 0.25);
}

.send-button:active {
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2));
}